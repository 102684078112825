import React from "react";
import Layout from "../components/layout.js"
import Typography from '@material-ui/core/Typography';

const i18n = {
  'cn': {
    notFound: '你访问的页面不存在哟',
  },
  'en': {
    notFound: 'Target Page Not Found',
  }
}

function NotFoundPage(props){
  const defaultLang = 'cn';
  const lang = props.pageContext.lang || defaultLang;
  const texts = i18n[lang];
  return (
    <Layout lang={lang}>
      <Typography variant='h4' align='center'>
        {texts.notFound}
      </Typography>
    </Layout>
  );
}

export default NotFoundPage;
